<template>
    <v-container fluid class="pa-0">
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :hide-default-header="false" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1"></v-col>
                <v-col cols="6" md="6" class="text-right pa-2">
                    <v-btn color="success" depressed rounded class="white--text" @click="createPrice()">
                        <v-icon left dark>mdi-pencil-plus-outline</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.tpr_amount_ht`]="{ item }">
            <v-text-field outlined hide-details dense class="rounded-lg" v-model="item.tpr_amount_ht" type="number"></v-text-field>
        </template>

        <template v-slot:[`item.tpr_typ_id`]="{ item }">
            <v-chip v-if="typeListById[item.tpr_typ_id] && item.tpr_id" small outlined label :color="typeListById[item.tpr_typ_id].typ_color">
                {{ typeListById[item.tpr_typ_id].typ_description }}
            </v-chip>
            <v-select v-else :items="typeListByGroup['UNI']" item-text="typ_name" item-value="typ_id" outlined dense
                    v-model="item.tpr_typ_id" label="Type" >
            </v-select>
        </template>
        <template v-slot:[`item.tpr_date`]="{ item }">
            <v-chip v-if="item.tpr_id" label outlined :color="(taskTypeItem.tty_tpr_date == item.tpr_date) ? 'green' : 'grey'">
                {{ $date(item.tpr_date).format('DD/MM/YYYY') }}
            </v-chip>
            <DatePicker v-else label="Actif depuis" :objectForm="item" fieldName='tpr_date'></DatePicker>
        </template>
        <template v-slot:[`item.tpr_updated_at`]="{ item }">
            <v-chip label outlined>{{ $date(item.tpr_updated_at).format('DD/MM/YYYY') }}</v-chip>
        </template>
        <template v-slot:[`item.tpr_tty_id`]="{ item }">
            <v-btn v-if="taskTypeItem.tty_tpr_date !== item.tpr_date" outlined small color="green" class="mr-2" @click="alocatePrice(item.tpr_date)">
                <v-icon left class="mr-2">mdi-paperclip-check</v-icon>
            </v-btn>
            <v-btn outlined small color="grey" class="ml-2 elevation-1" @click="saveItem(item)">
                <v-icon left class="mr-2">mdi-content-save-check-outline</v-icon>Enregistrer
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-container>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'

import { mapState, mapActions, mapWritableState } from 'pinia'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

import { useTasktypepriceStore } from '@/stores/tasktypeprice'
import { useTaskTypeStore } from '@/stores/tasktype'

export default {
    name: 'tasktypeprices_edit',
    props: [ 'ttyId' ],
    mixins: [ ],
    components: {
        DatePicker,
    },
    computed: {
        ...mapWritableState(useTaskTypeStore, {taskTypeItem: 'item' }),
        ...mapState(useTasktypepriceStore, { item: 'item', itemList: 'itemList' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListByIdAll' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
        validUplodProduct(){
            if( this.form.file_product_name.length > 0 ){
                return true
            } else {
                return false
            }
        }
    },
    data: function () {
        return {
            loading: { saveProgress: false },
            validFrom: true,
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Unité',          align: 'left',   value: 'tpr_typ_id'},
                { text: 'Date',           align: 'center', value: 'tpr_date'},
                { text: 'Mise à jour',    align: 'center', value: 'tpr_updated_at'},
                { text: 'Montant',        align: 'right',   value: 'tpr_amount_ht', width: '150px'},
                { text: 'Action',         align: 'right',  value: 'tpr_tty_id'},
            ],
        }
    },
    beforeMount() {},
    async mounted(){
        this.getItems('?tpr_tty_id=' + this.ttyId)
    },
    methods: {
        ...mapActions(useTasktypepriceStore, { fetchItem: 'fetchItem', fetchItems: 'fetchItems', updateItem: 'updateItem', storeItem: 'storeItem' }),
        ...mapActions(useTaskTypeStore, { updateTaskTypeItem: 'updateItem' }),
        async getItems(){
            let id = '?per_page=false&order_by=tpr_date&order_direction=desc&tpr_tty_id=' + this.taskTypeItem.tty_id || ''
            await this.fetchItems(id)
        },
        async saveItem (item) {
             // Enregistrement du formulaire
            this.loading.fetchData = true
            if( !item.tpr_id ){
                await this.storeItem(item)
                this.alocatePrice(this.item.tpr_date)
            } else {
                await this.updateItem(item)
            }
            this.$root.$toast({ color: 'success', text: 'Tarif enregistré !' })
            this.getItems()
            this.loading.fetchData = false
        },
        createPrice(){
            let newItem = {
                tpr_id: 0, tpr_tty_id: this.taskTypeItem.tty_id, tpr_typ_id: 'UNI01', tpr_amount_ht: 0, tpr_date: this.$date().format('YYYY-MM-DD')
            }
            this.itemList.unshift( newItem )
        },
        async alocatePrice(tprDate){
            this.taskTypeItem.tty_tpr_date = tprDate
            await this.updateTaskTypeItem()
            this.getItems()
        }
        
    },
}
</script>