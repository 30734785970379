<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
    </PageHeader>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom"  v-model="objZone.zon_name"></v-text-field>
        </v-flex>

        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="cacheState" v-model="objZone.zon_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn :disabled="!objZone.zon_id" rounded color="error" @click.native="deleteForm()"><v-icon left dark>mdi-close</v-icon>Supprimer</v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text href="/#/zones/"><v-icon left dark>mdi-close</v-icon>Annuler</v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
            <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import { cacheGetters } from "@/stores/cache.module"
import { ttyObj }       from '@/mixins/tty_obj.js'

export default {
    name: 'zone-edit',
    props: [ 'contextStyle'],
    mixins: [ ttyObj ],
    components: { PageHeader },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            loadingData: true,
            prefetchAc: true,
            modify: false,
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
            cacheState: cacheGetters.getStates('GEN') || [],
            objZone: {
                zon_id        : 0,
                zon_uid       : '',
                zon_sta_id    : 'GEN01',
                zon_name      : '',
                zon_created_at: ''
            }
        }
    },
    computed: { },
    beforeMount: function(){},
    mounted:function (){
        if( ( this.$route.params.zon_id ) && this.$route.params.zon_id > 0 ){
            this.modify = true
            this.objZone.zon_id = this.$route.params.zon_id
            this.prefetchAc = false
            this.getForm()
        } else {
            this.loadingData = false
        }
    },
    methods: {
        async getForm(){
            this.$http.get('/zones/' + this.objZone.zon_id).then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.objZone[prop] !== undefined ){
                        this.objZone[prop] = response.data.data[prop] 
                    }
                }
            })
            this.loadingData = false
            
        },
        async saveForm () {
            if( this.objZone.zon_id > 0 ){
                await this.$http.put('/zones/' + this.objZone.zon_id, this.objZone)
                this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                this.$router.push({ path: '/tasktypes' })
            } else {
                await this.$http.post('/zones/', this.objZone)
                this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                this.$router.push({ path: '/tasktypes' })
            }
        },
        async deleteForm(){
            await this.$http.delete('/zones/' + this.objZone.zon_id)
            this.$root.$toast({ color: 'success', text: 'Fiche supprimée !' })
            this.$router.push({ path: '/zones' })
        },
        getCacheType: cacheGetters.getTypes,
    }
}
</script>