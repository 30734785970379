<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyleModified">
        <!-- <template #right><v-btn small><v-icon small color="success">mdi-file-excel</v-icon></v-btn></template> -->
        <template #right >
            <v-btn v-if="!alertModal" small loading><v-icon small color="success">mdi-target</v-icon></v-btn>
            <v-btn v-if="alertModal" small><v-icon small color="success">mdi-target</v-icon> Precision: {{ Math.round(precision / 2) }} m</v-btn>
        </template>
    </PageHeader>
    <v-sheet class="ma-4 rounded-lg elevation-2">
        <EditEvent v-if="alertModal" :switchModal="switchModal"
                :xxxId="0" :eveTable="'zon'" :eveType="'MEV04'" :cooridnates="cooridnates" />
    </v-sheet>

</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import EditEvent from '@/components/events/EventForm'

import { cacheGetters } from "@/stores/cache.module"
//import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'geoalert-page',
    props: ['toast', 'contextStyle'],
    components: {
        PageHeader,
        EditEvent
    },
    data: function () {
        return {
            loading: { searchPos: true, getXls: false, getTasks: false },         // Enregistrement en cours...
            alertModal: false,
            cooridnates: { lat:0, lng: 0 },
            precision: 0,
            watchId : null,
            contextStyleModified : this.contextStyle
        }
    },
    beforeMount: function(){
        this.contextStyleModified.title = "Nouvelle Alerte"
        this.contextStyleModified.icon  = "mdi-alert"
    },
    mounted: function (){
        this.findCoordinates()
    },
    methods: {
        switchModal(){
            this.$router.push({ path: '/map' })
        },
        findCoordinates() {
            this.$root.$toast({ color: 'warning', text: 'Recherche de la position' })
            this.watchId = navigator.geolocation.watchPosition(this.successCallback, this.errorCallback, { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 });

            //if(navigator.geolocation){
            //    navigator.geolocation.getCurrentPosition((position) => {
            //        this.cooridnates.lat = position.coords.latitude
            //        this.cooridnates.lng = position.coords.longitude
            //        this.alertModal = true
            //    },
            //    (err) => { 
            //        this.$root.$toast({ color: 'error', text: err.message })
            //    })
            //} else {
            //    this.$root.$toast({ color: 'error', text: 'La géolocalisation n\'est pas supporté par votre navigateur' })
            //}
        },
        successCallback(position){
            if( !this.precision || (this.precision > position.coords.accuracy)){
                this.cooridnates.lat = position.coords.latitude
                this.cooridnates.lng = position.coords.longitude
                this.precision = position.coords.accuracy
                this.alertModal = true
            }
        },
        errorCallback(error){
            switch(error.code){
                case error.PERMISSION_DENIED:
                    this.$root.$toast({ color: 'error', text: "L'utilisateur n'a pas autorisé l'accès à sa position" })
                break;
                case error.POSITION_UNAVAILABLE:
                    this.$root.$toast({ color: 'error', text: "L'emplacement de l'utilisateur n'a pas pu être déterminé" })
                break;
                case error.TIMEOUT:
                    this.$root.$toast({ color: 'error', text: "Le service n'a pas répondu à temps" })
                break;
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    beforeDestroy() {
        //this.subTask.unsubscribe()
    },
}
</script>