<template>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" class="ma-4 rounded-lg elevation-2">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-2">
                <v-btn color="success" depressed rounded class="white--text" to="/zones/0">
                        <v-icon left dark>mdi-pencil-plus-outline</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.zon_sta_id`]="{ item }">
            <v-chip class="ma-2" outlined label :color="stateListById[item.zon_sta_id].sta_color">
                {{ stateListById[item.zon_sta_id].sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.zon_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/zones/' + item.zon_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Ouvrir
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useStateStore } from '@/stores/state'
import { useZoneStore } from '@/stores/zone'

export default {
    name: 'zone-list',
    props: [ ],
    mixins: [ ],
    computed: {
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
        ...mapState(useZoneStore, { itemList: 'itemList' }),
    },
    data: function () {
        return {
            loading: { getgetTaskItems: false },
            validFrom: true,
            taskSelected : {},
            selectFields : {},
            actionTask   : [],

            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',     align: 'left', value: 'zon_name'},
                { text: 'Statut',  align: 'left', value: 'zon_sta_id'},
                { text: 'Action',  align: 'right', value: 'zon_id'},
            ],
        }
    },
    async beforeMount(){
        await this.fetchZones('?per_page=false')
    },
    mounted: function (){

    },
    methods: {
        ...mapActions(useZoneStore,     {fetchZones:  'fetchItems'}),
    },
}
</script>