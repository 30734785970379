<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
      <!-- <template #right><v-btn small><v-icon small color="success">mdi-file-excel</v-icon></v-btn></template> -->
    </PageHeader>

    <TaskTypesList />

</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import TaskTypesList from '@/components/tasktypes/TaskTypesList'

//import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'tasktypes-page',
    props: ['contextStyle'],
    components: {
        PageHeader,
        TaskTypesList
    },
    data: function () {
        return {
            loading: { getXls: false, getTasks: false },         // Enregistrement en cours...
            items: [],
            enableAdd: true,
            modalEvent : false
        }
    },
    computed: { },
    created(){},
    mounted: function (){
        //this.search = cacheGetters.getSearchBarItem('tasktypes') || ''
        //this.getItems()
    },
    methods: {
        async getItems(){
            this.items = []
        }
    },
    beforeDestroy() {
        //this.subTask.unsubscribe()
    },
}
</script>