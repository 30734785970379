<template>
    <div>
        <v-autocomplete ref="autoTeam" v-model="listSelected" :items="listItem" :autofocus="initFields.autofocus" class=""
            @input="autoImput" :search-input.sync="search" @change="emitChange" @blur="emitBlur"
            :item-text="textToshow" :item-value="initFields.index" :label="title" 
            :style="(initFields.zindex) ? 'z-index:' + initFields.zindex + ';' : ''" :class="initFields.class"
            :multiple="multiSelect" :prepend-icon="preprendIcon" :chips="initFields.chips" :small-chips="initFields.smallchips" clearable hide-selected 
            :auto-select-first= "initFields.autoselectfirst" :disabled="isDisabled" :background-color="this.initFields['background-color']"
            :outlined="initFields.outlined" :dense="initFields.dense" :rounded="initFields.rounded" :deletable-chips="initFields.deletablechips">
            <template v-slot:no-data>
                <span v-if="notfoundClick" @click="notfoundClick" class="ma-2">{{initFields.notfound}} --</span>
                <span v-else class="ma-2">{{initFields.notfound}}</span>
            </template>
        </v-autocomplete>
    </div>
    </template>
    
    <script>
    
    export default {
        name: 'autocomplete_ressource',
        props: ['ressource', 'listParent', 'fields', 'title', 'listObject', 'disabled', 'notfoundClick', 'searchinput'], 
        data: function () {
            return {
                loading     : { getVersion: false, getRessource: false },
                ischange    : false,
                search      : null,
                listSelected: null,
                listItem    : [],
                saveRessource : false,
                modalRessource: false,
                multiSelect   : true,
                preprendIcon  : 'mdi-account',
                params        : '',
                isDisabled    : this.disabled || false,
                initFields    : {}
            }
        },
        watch: {
            search (val) {
                if(this.search && this.search.length > 0){
                    this.$emit('update:searchinput', this.search)
                }
    
                if( this.loading.getRessource ){
                    clearTimeout(this.loading.getRessource)
                }
                if( this.ressource && val && val.length >= this.initFields.minLength ){
                    var searchTab = this.fields.search.split(',')
                    var search = ''
                    searchTab.forEach( (elem, idx) => {
                        search += '&' + elem.trim() 
                        search += (idx) ? '=%||' : '=%'
                        search += val + '%'
                    })
                    if( this.ressource.substring(0, 4) == 'ext-' ){
                        search = searchTab[0].trim() + val //Todoo multi -> delete this line
                    } 
                    //else {
                    //    search = '&' + searchTab[0].trim() + '=%' + val + '%' //Todoo multi -> delete this line
                    //}
                    this.loading.getRessource = setTimeout( () => {
                        this.getRessource(search)
    
                    }, 800)
                } else {
                    return false
                }
            },
            listObject() {
                this.listSelected = this.listObject
            }
        },
        beforeMount: function(){
            this.initFields = Object.assign({}, this.fields)
    
            this.initFields.outlined           = this.fields.outlined || true
            this.initFields.dense              = this.fields.dense || false
            this.initFields.filled             = this.fields.filled || false
            this.initFields.flat               = this.fields.flat || false
            this.initFields['full-width']      = this.fields['full-width'] || false
            this.initFields.reverse            = this.fields.reverse || false
            this.initFields.rounded            = this.fields.rounded || false
            this.initFields['background-color']= this.fields['background-color'] || undefined
            this.initFields.deletablechips     = this.fields.deletablechips || false
            this.initFields.chips              = (this.fields.chips !== undefined) ? this.fields.chips : true
            this.initFields.smallchips         = (this.fields.smallchips !== undefined) ? this.fields.smallchips : true
    
            this.initFields.autofocus       = this.fields.autofocus || false
            this.initFields.autoselectfirst = this.fields.autoselectfirst || false
            this.initFields.params          = this.fields.params || ''
            this.initFields.addparams       = this.fields.addparams || ''
            this.initFields.notfound        = this.fields.notfound || 'Aucun élément de trouvé'
            this.initFields.prefetch        = this.fields.prefetch || false
            this.initFields.resultlimit     = this.fields.resultlimit || 25
            this.initFields.minLength       = this.fields.minLength || 2
            this.params                 = ( this.fields.params.length > 0 ) ? '&' + this.fields.params : '';
        },
        mounted:function (){
            this.initResource()
        },
        methods: {
            initResource(){
                this.multiSelect  = (this.fields.multiSelect == undefined) ? true : this.fields.multiSelect
                this.preprendIcon = (this.fields.preprendIcon == undefined) ? undefined : this.fields.preprendIcon
                this.outlined     = (this.fields.outlined === true) ? true : false
                this.dense        = (this.fields.dense === true) ? true : false
                if( this.multiSelect && this.listObject !== undefined && this.listObject.length > 0 ){
                    this.listSelected = this.listObject
                } else if( !this.multiSelect && this.listObject !== undefined ){
                    this.listSelected = this.listObject
                }
                if( this.fields.prefetch ){
                    this.getRessource('').then( () => {
                        this.listItem = this.mergeArrayObject(this.listItem, this.listParent, this.fields.index)
                    })
                } else {
                    this.listItem     = this.listParent
                }
            },
            getRessource(search){
                return new Promise( (resolve, reject) => {
                    if( this.ressource == 'ext-companies' ){
                        //this.$http.removeHeader()
                        this.$http.customRequest({
                            headers: {
                                //Authorization: 'Bearer ' + process.env.VUE_APP_INSEE_KEY,
                                'X-INSEE-Api-Key-Integration' : process.env.VUE_APP_INSEE_API_KEY,
                                
                            },
                            baseURL: '',
                            method: 'get',
                            transformRequest: (data, headers) => { delete headers.common['Authorization']; return data; },
                            url: process.env.VUE_APP_INSEE_URL + 'siret/' + '?' + search + "&nombre=15"
                        }).then( (response) => {
                            if( response?.data?.etablissements ){
                                let data = []
                                let company, key
                                for (key = 0; (company = response.data.etablissements[key]) !== undefined; key++) {
                                    let label = company.uniteLegale.denominationUniteLegale
                                    label += ' (' + company.adresseEtablissement.numeroVoieEtablissement
                                    label += ' ' + company.adresseEtablissement.typeVoieEtablissement
                                    label += ' ' + company.adresseEtablissement.libelleVoieEtablissement
                                    label += ', ' + company.adresseEtablissement.libelleCommuneEtablissement
                                    label += ')' 
                                    data[key] = {
                                        label : label,
                                        value : company.siren,
                                        id    : key,
                                        siret : company.siret, 
                                        item  : company
                                    }
                                }
                                this.listItem = this.listParent.concat(data)
                                resolve('resolved')
                            } else {
                                this.listItem = []
                                resolve('resolved')
                            }
                        }).catch( () => { 
                            this.listItem = []
                            resolve('resolved')
                        })
                    } else {
                        if( search == null || search == undefined ){
                            search = ''
                        }
                        let uri = '/' + this.ressource + '/?per_page=' + this.initFields.resultlimit + this.params + encodeURI(search)
                        uri += ( this.initFields.addparams.length > 0 ) ? this.initFields.addparams : ''
                        this.$http.get( uri ).then( (response) => {
                            this.listItem = this.listParent.concat(response.data.data)
                            resolve('resolved')
                        }).catch( (error) => { 
                            reject(error)
                        })
                    }
    
                })
            },
            autoImput(){
                var listObject = []
                this.listItem.forEach(element => {
                    if( this.listSelected && this.listSelected !== undefined && this.listSelected.isArray ){
                        if( this.listSelected.indexOf( element[ this.fields.index ] ) > -1 ){
                            listObject.push( element ) //this.listParent.push( element )
                        }
                    } else {
                        listObject.push( element )
                    }
                })
                this.$emit('update:listParent', listObject)
                this.$emit('update:listObject', this.listSelected)
                this.$emit('change', this.listSelected)
            },
            switchModal(name, mode){
                name    = name || 'modalressource'
                mode    = mode || 0
                //refresh = refresh || false
                this[name] = mode
            },
            textToshow( item ){
                let text   = ''
                let fields = this.fields.text.split(',')
                fields.forEach(element => {
                    text += (text.length > 0) ? ' - ' + item[element] : item[element]
                })
                return text
            },
            mergeArrayObject(a, b, prop) {
                let reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
                return reduced.concat(b)
            },
            emitChange(){
                if( this.listSelected !== null && this.listSelected !== undefined && this.listItem.length > 0 ){
                    let obj = this.listItem.find(o => o[this.fields.index] === this.listSelected);
                    this.$emit('change', obj)
                }
            },
            emitBlur(){
                if( this.listSelected !== null && this.listSelected !== undefined && this.listItem.length > 0 ){
                    let obj = this.listItem.find(o => o[this.fields.index] === this.listSelected);
                    this.$emit('blur', obj)
                }
            }
        },
        components: {
    
        }
    }
    </script>