import ApiService from '@/services/api'
import { defineStore } from 'pinia'
//import { taskUpdateService } from '@/rxjsServices';

const ENDPOINT = '/states/'
const ITEM_ID  = 'sta_id'

export const useStateStore = defineStore('states', {
    //storage option
    persist: true,
    // convert to a function
    state: () => ({
        item: null,
        itemList: [],
        itemSearchList: '',
        meta: {}
    }),
    getters: {
      itemListByGroupVisible() {
        let itemsObject = {}
        for (let i=0, len=this.itemList.length; i<len; i++){
          if( !this.itemList[i].sta_visibility ){ continue; }
          if( !itemsObject[ this.itemList[i].sta_id.substring(0, 3) ] ){
            itemsObject[ this.itemList[i].sta_id.substring(0, 3) ] = []
          }
          itemsObject[ this.itemList[i].sta_id.substring(0, 3) ].push(this.itemList[i])
        }
        return itemsObject || {}
      },
      itemListByGroup() {
        let itemsObject = {}
        for (let i=0, len=this.itemList.length; i<len; i++){
          //if( !this.itemList[i].sta_visibility ){ continue; }
          if( !itemsObject[ this.itemList[i].sta_id.substring(0, 3) ] ){
            itemsObject[ this.itemList[i].sta_id.substring(0, 3) ] = []
          }
          itemsObject[ this.itemList[i].sta_id.substring(0, 3) ].push(this.itemList[i])
        }
        return itemsObject || {}
      },
      itemListById() {
        let itemsObject = {}
        for (let i=0, len=this.itemList.length; i<len; i++){
          //if( !this.itemList[i].sta_visibility ){ continue; }
          itemsObject[ this.itemList[i].sta_id ] = this.itemList[i]
        }
        return itemsObject
      }
    },
    actions: {
        fetchItem (id, options) {
            return new Promise( (resolve, reject) => {
                options = (options) ? '/' + options : ''
                ApiService.get(ENDPOINT + id + options).then( (response) => {
                    this.SET_ITEM(response.data.data)
                    resolve(this.item)
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        fetchItems (options) {
            return new Promise( (resolve, reject) => {
                options = (options) ? options : ''
                ApiService.get( ENDPOINT + options ).then( (response) => {
                    this.meta = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.SET_ITEMS(response.data.data)
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        storeItem () {
            return new Promise( (resolve, reject) => {
              ApiService.post( ENDPOINT + this.item ).then( (response) => {
                this.SET_ITEM(response.data.data)
                resolve(this.item)
              }).catch( (error) => { 
                reject(error)
              })
            })
          },
          updateItem () {
            return new Promise( (resolve, reject) => {
              ApiService.put( ENDPOINT + this.item[ITEM_ID], this.item ).then( (response) => {
                this.SET_ITEM(response.data.data)
                resolve(this.item)
              }).catch( (error) => { 
                reject(error)
              })
            })
          },
          destroyItem (id) {
            return new Promise( (resolve, reject) => {
              this.item[ITEM_ID] = (id) ? id : this.item[ITEM_ID]
              ApiService.delete(ENDPOINT + this.item[ITEM_ID]).then( () => {
                resolve('resolved')
              }).catch( (error) => { 
                reject(error)
              })
            })
          },
        //***************************MUTATIONS ***************************************/
        SET_ITEM (item) {
        this.item = item
        },
        SET_ITEMS (itemList) {
        this.itemList = itemList
        },
        RESET_ITEM () {
            this.item = { sta_id: '' }
        }
    }
})
