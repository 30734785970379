import { render, staticRenderFns } from "./TaskTypePriceList.vue?vue&type=template&id=424abec0"
import script from "./TaskTypePriceList.vue?vue&type=script&lang=js"
export * from "./TaskTypePriceList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports