import Vue           from 'vue'
import App           from '@/App.vue'
import router        from '@/services/router'
import vuetify       from '@/plugins/vuetify';
import { createPinia, PiniaVuePlugin } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

import '@/plugins/download'
import '@/plugins/dayjs'
import '@/plugins/apexchart'
import '@/plugins/api'
import '@/plugins/build'
import '@/plugins/storage'
import '@/plugins/user'

Vue.use(PiniaVuePlugin)
export const pinia = createPinia()
const piniaStore = createPersistedState({
  key: id => `__persisted__${id}`
})
pinia.use(piniaStore)

// Event bus
Vue.prototype.$eventBus = new Vue({})

new Vue({
  vuetify,
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')
