<template>
    <v-card>
        <v-card-text>
        <v-layout row wrap>
            <v-flex xs12 class="pa-2">
                <v-text-field label="Nom" v-model="item.doc_name"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="types" item-text="typ_name" item-value="typ_id" 
                    v-model="item.doc_typ_id" label="Statut" >
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="table" item-text="table_name" item-value="table_id" 
                    v-model="item.doc_table_id" label="Association" disabled >
                </v-select>
            </v-flex>
            <v-flex xs12 class="pa-2">
                <v-text-field label="Description" v-model="item.doc_description"></v-text-field>
            </v-flex>
            <v-flex xs12 class="pa-2">
                <v-file-input @change="getFileDocument" ref="documentfile" v-model="item.obj_file" show-size label="Ajouter fichier / Prendre une photo"></v-file-input>
            </v-flex>
        </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions>
            <v-menu v-if="item.eve_id > 0" v-model="optionMenu" offset-y transition="slide-x-transition" bottom right >
                <template v-slot:activator="{ on }">
                <v-btn v-on="on" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-btn class="ma-0" color="error" @click.native="deleteDocument()" :disabled="saveProgress">
                    <v-icon left>mdi-trash-can</v-icon>Supprimer
                </v-btn>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn   
                color="success" 
                :loading="saveProgress" @click.native="saveDocument()" :disabled="saveProgress">
                Enregistrer
            </v-btn>
            <v-btn outlined color="grey" @click="switchModal('modalDocument', 0)">
                Fermer
            </v-btn>
        </v-card-actions>
    </v-card>
    </template>
    
    <script>
    import { mapState, mapWritableState, mapActions } from 'pinia'
    import { useDocumentStore } from '@/stores/document'
    import { useTypeStore } from '@/stores/type'
    import { useStateStore } from '@/stores/state'
    
    export default {
        name: 'document-edit',
        props: ['toast', 'xxxId', 'docId', 'docTable', 'docType', 'switchModal', 'withFile'],
        computed: {
            ...mapWritableState(useDocumentStore, {item: 'item' }),
            ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
            ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
        },
        data: function () {
            return {
                dateMenu: { date_start:false, date_end:false },
                saveProgress: false,         // Enregistrement en cours...
                states : [],
                types : [],
                table : [
                    { table_name: 'Site',                table_id: 'trs' },
                    { table_name: 'Evenement',           table_id: 'eve' },
                ],
                optionMenu: false,
            }
        },
        mounted:function (){
            this.init()
        },
        beforeUpdate:function (){
            this.init()
        },
        methods: {
            ...mapActions(useDocumentStore, { fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem:'destroyItem' }),
            init(){
                this.types = this.types.concat( this.typeListByGroup['DOC'] )
                if( this.docId !== undefined && this.docId > 0 ){
                    this.item.doc_id = this.docId
                    this.fetchItem(this.item.doc_id)
                } else {
                    this.fetchItem(this.item.doc_id)
                    this.item.doc_id       = 0
                    this.item.doc_typ_id   = 'DOC03' //this.types[0].typ_id
                    this.item.doc_table_id = this.docTable
                    this.item.doc_xxx_id   = this.xxxId
                    this.item.doc_usr_id_created   = 1
                    if( this.withFile !== undefined ){
                        this.item.doc_name = this.withFile.name
                        this.item.obj_file = this.withFile
                        this.getFileDocument(this.withFile)
                    }
                }
            },
            async saveDocument(){
                if( this.item.doc_id !== undefined && this.item.doc_id > 0 ){
                    this.updateItem().then( () => {
                        this.switchModal('modalDocument', 0, true)
                    })
                } else {
                    this.storeItem().then( () => {
                        this.switchModal('modalDocument', 0, true)
                    })
                }
            },
            deleteDocument(){
                this.deleteDocumentDocument( () => {
                    this.switchModal('modalDocument', 0, true)
                })
            },
            getFileDocument(file){
                if( this.item.doc_name.length == 0 ){
                    let nameTab = file.name.split(".")
                    this.item.doc_name = nameTab[0]
                }
                this.item.doc_filename = file.name
            },
        },
        components: { }
    }
    </script>